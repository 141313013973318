body {
  font-family: work sans, Arial, sans-serif;
  font-weight: 500 !important;
  font-size: 16px;
  line-height: 1.7;
  color: #828282;
  background: #fff;
}

.slider-img-size {
  width: 100%;
  height: 629px;
}

@media only screen and (max-width: 540px) {
  .slider-img-size {
    width: 100%;
    height: 320px;
  }
}

@media only screen and (min-width: 541px) {
  .slider-img-size {
    width: 100%;
    height: 320px;
  }
}

@media only screen and (min-width: 720px) {
  .slider-img-size {
    width: 100%;
    height: 450px;
  }
}

@media only screen and (min-width: 960px) {
  .slider-img-size {
    width: 100%;
    height: 500px;
  }
}

@media only screen and (min-width: 1140px) {
  .slider-img-size {
    width: 100%;
    height: 629px;
  }
}

.performanz-nav {
  margin: 0;
  padding: 0;
  width: 100%;
  padding: 0;
  z-index: 1001;
}

.performanz-nav .top-menu {
  padding: 28px 0;
}

.performanz-nav .top {
  background: #f85a16;
  padding: 7px 0;
  margin-bottom: 0;
}

.performanz-nav .top .num,
.performanz-nav .top .performanz-social {
  display: inline-block;
  margin: 0;
}

.performanz-nav .top .num {
  font-size: 13px;
  padding-right: 20px;
  margin-right: 10px;
  display: -moz-inline-stack;
  display: inline-block;
  zoom: 1;
  *display: inline;
  border-right: 1px solid rgba(255, 255, 255, 0.2);
  letter-spacing: 0;
  color: rgba(255, 255, 255, 0.7);
}

.performanz-nav .top .performanz-social li {
  font-size: 14px;
}

.performanz-nav .top .performanz-social li a {
  padding: 0 7px;
}

.performanz-nav .top .performanz-social li a i {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.7);
}

.performanz-nav #performanz-logo {
  font-size: 24px;
  margin: 0;
  padding: 0;
  text-transform: uppercase;
  font-weight: 700;
  font-weight: 700;
  font-family: work sans, Arial, sans-serif;
}

.performanz-nav #performanz-logo a span {
  color: #f85a16;
}

.nav-logo {
  width: 50px !;
}

.performanz-nav a {
  padding: 5px 10px;
  color: #000 !important;
}

@media screen and (max-width: 768px) {
  .performanz-nav .menu-1 {
    display: none;
  }
}

.performanz-nav ul {
  padding: 0;
  margin: 5px 0 0;
}

.performanz-nav ul li {
  padding: 0;
  margin: 0;
  list-style: none;
  display: inline;
}

.performanz-nav ul li a {
  font-size: 13px;
  font-weight: 700;
  padding: 30px 15px;
  text-transform: uppercase;
  letter-spacing: 2px;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}

.performanz-nav ul li a:hover,
.performanz-nav ul li a:focus,
.performanz-nav ul li a:active {
  color: #000 !important;
}

.performanz-nav ul li.has-dropdown {
  position: relative;
}

.performanz-nav ul li.has-dropdown .dropdown {
  width: 140px;
  -webkit-box-shadow: 0 14px 33px -9px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0 14px 33px -9px rgba(0, 0, 0, 0.75);
  box-shadow: 0 14px 33px -9px rgba(0, 0, 0, 0.75);
  z-index: 1002;
  visibility: hidden;
  opacity: 0;
  position: absolute;
  top: 40px;
  left: 0;
  text-align: left;
  background: #f85a16;
  padding: 20px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px;
  -webkit-transition: 0;
  -o-transition: 0;
  transition: 0;
}

.performanz-nav ul li.has-dropdown .dropdown:before {
  bottom: 100%;
  left: 40px;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-bottom-color: #f85a16;
  border-width: 8px;
  margin-left: -8px;
}

.performanz-nav ul li.has-dropdown .dropdown li {
  display: block;
  margin-bottom: 7px;
}

.performanz-nav ul li.has-dropdown .dropdown li:last-child {
  margin-bottom: 0;
}

.performanz-nav ul li.has-dropdown .dropdown li a {
  padding: 2px 0;
  display: block;
  color: #f2f2f2;
  line-height: 1.2;
  text-transform: none;
  font-size: 13px;
  letter-spacing: 0;
}

.performanz-nav ul li.has-dropdown .dropdown li a:hover {
  color: #fff;
}

.performanz-nav ul li.has-dropdown:hover a,
.performanz-nav ul li.has-dropdown:focus a {
  color: #fff;
}

.performanz-nav ul li.btn-cta a {
  color: #fff;
}

.performanz-nav ul li.btn-cta a span {
  background: #f85a16;
  padding: 4px 20px;
  display: -moz-inline-stack;
  display: inline-block;
  zoom: 1;
  *display: inline;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  -ms-border-radius: 100px;
  border-radius: 100px;
}

.performanz-nav ul li.btn-cta a:hover span {
  -webkit-box-shadow: 0 14px 20px -9px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0 14px 20px -9px rgba(0, 0, 0, 0.75);
  box-shadow: 0 14px 20px -9px rgba(0, 0, 0, 0.75);
}

.performanz-nav ul li.active>a {
  color: #f85a16 !important;
}

.performanz-nav #performanz-logo {
  font-weight: bold;
  font-size: 24px;
  margin: 0;
  padding: 0;
  text-transform: uppercase;
  font-weight: 700;
  font-weight: 700;
  font-family: work sans, Arial, sans-serif;
}

.performanz-nav #performanz-logo a {
  font-weight: bold;
  color: #f85a16;
}

.performanz-nav #performanz-logo a {
  font-weight: bold;
  text-decoration: none;
}

.performanz-bg-section {
  background: rgba(0, 0, 0, 0.05);
}

.product {
  padding: 25px;
  background: #fff;
  -webkit-box-shadow: 0 10px 23px -10px rgba(0, 0, 0, 0.43);
  -moz-box-shadow: 0 10px 23px -10px rgba(0, 0, 0, 0.43);
  -ms-box-shadow: 0 10px 23px -10px rgba(0, 0, 0, 0.43);
  -o-box-shadow: 0 10px 23px -10px rgba(0, 0, 0, 0.43);
  box-shadow: 0 10px 23px -10px rgba(0, 0, 0, 0.43);
}

.services {
  padding: 25px;
  height: 450px;
  background: #fff;
  -webkit-box-shadow: 0 10px 23px -10px rgba(0, 0, 0, 0.43);
  -moz-box-shadow: 0 10px 23px -10px rgba(0, 0, 0, 0.43);
  -ms-box-shadow: 0 10px 23px -10px rgba(0, 0, 0, 0.43);
  -o-box-shadow: 0 10px 23px -10px rgba(0, 0, 0, 0.43);
  box-shadow: 0 10px 23px -10px rgba(0, 0, 0, 0.43);
}

.services span {
  width: 100%;
  display: block;
  margin-bottom: 30px;
}

.services span img {
  width: 60px;
  margin: 0 auto;
}

.services h3 {
  text-transform: uppercase;
  font-size: 16px;
  letter-spacing: 2px;
  font-weight: 500;
  position: relative;
}

.services h3:after {
  position: absolute;
  bottom: -10px;
  left: 0;
  right: 0;
  width: 40px;
  height: 2px;
  margin: 0 auto;
  background: #f85a16;
  content: "";
}

@media screen and (max-width: 992px) {
  .services {
    margin-top: 0;
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 768px) {
  .services {
    margin-top: 0;
    margin-bottom: 20px;
  }
}

#performanz-services,
#performanz-started,
#performanz-testimonial,
#performanz-trainer,
#performanz-gallery,
#performanz-schedule,
#performanz-pricing,
#performanz-blog,
#performanz-contact,
#performanz-footer {
  padding: 7em 0;
  clear: both;
}

@media screen and (max-width: 768px) {

  #performanz-services,
  #performanz-started,
  #performanz-testimonial,
  #performanz-trainer,
  #performanz-gallery,
  #performanz-schedule,
  #performanz-pricing,
  #performanz-blog,
  #performanz-contact,
  #performanz-footer {
    padding: 3em 0;
  }
}

.end-43 {
  right: 39% !important;
  bottom: 15px !important;
}

.btn-warning {
  background: #f85a16 !important;
  color: #fff !important;
  border: 2px solid #f85a16 !important;
}

.btn-warning:hover,
.btn-warning:focus,
.btn-warning:active {
  background: #f96c2f !important;
  border-color: #f96c2f !important;
}

.btn-warning.btn-outline {
  background: 0 0 !important;
  color: #f85a16 !important;
  border: 1px solid #f85a16 !important;
}

.btn-warning.btn-outline:hover,
.btn-warning.btn-outline:focus,
.btn-warning.btn-outline:active {
  background: #f85a16 !important;
  color: #fff !important;
}

.btn-outline {
  background: 0 0 !important;
  border: 1px solid gray !important;
  font-size: 16px !important;
  -webkit-transition: 0.3s !important;
  -o-transition: 0.3s !important;
  transition: 0.3s !important;
}

.btn-outline:hover,
.btn-outline:focus,
.btn-outline:active {
  box-shadow: none !important;
}

#performanz-services .btn {
  margin-right: 4px !important;
  margin-bottom: 4px !important;
  font-family: work sans, Arial, sans-serif !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  -webkit-border-radius: 30px !important;
  -moz-border-radius: 30px !important;
  -ms-border-radius: 30px !important;
  border-radius: 30px !important;
  -webkit-transition: 0.5s !important;
  -o-transition: 0.5s !important;
  transition: 0.5s !important;
  padding: 8px 20px !important;
}

#performanz-header,
#performanz-counter,
.performanz-bg {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  position: relative;
}

#performanz-header .display-tc,
#performanz-counter .display-tc,
.performanz-cover .display-tc {
  display: table-cell !important;
  vertical-align: middle;
  padding: 0 40px;
}

#performanz-header .display-tc h1,
#performanz-header .display-tc h2,
#performanz-counter .display-tc h1,
#performanz-counter .display-tc h2,
.performanz-cover .display-tc h1,
.performanz-cover .display-tc h2 {
  margin: 0;
  padding: 0;
  color: #fff;
}

#performanz-header .display-tc h1,
#performanz-counter .display-tc h1,
.performanz-cover .display-tc h1 {
  margin-bottom: 20px;
  font-size: 54px;
  line-height: 1.3;
  text-transform: uppercase;
  font-weight: 400;
}

@media screen and (max-width: 768px) {

  #performanz-header .display-tc h1,
  #performanz-counter .display-tc h1,
  .performanz-cover .display-tc h1 {
    font-size: 30px;
  }
}

#performanz-header .display-tc h2,
#performanz-counter .display-tc h2,
.performanz-cover .display-tc h2 {
  font-size: 20px;
  line-height: 1.5;
  margin-bottom: 30px;
}

#performanz-header .display-tc .btn,
#performanz-counter .display-tc .btn,
.performanz-cover .display-tc .btn {
  padding: 15px 30px;
  background: #f85a16 !important;
  color: #fff;
  border: none !important;
  font-size: 18px;
}

#performanz-header .display-tc .btn:hover,
#performanz-counter .display-tc .btn:hover,
.performanz-cover .display-tc .btn:hover {
  background: #f85a16 !important;
  -webkit-box-shadow: 0 14px 30px -15px rgba(0, 0, 0, 0.75) !important;
  -moz-box-shadow: 0 14px 30px -15px rgba(0, 0, 0, 0.75) !important;
  box-shadow: 0 14px 30px -15px rgba(0, 0, 0, 0.75) !important;
}

#performanz-header .display-tc .form-inline .form-group,
#performanz-counter .display-tc .form-inline .form-group,
.performanz-cover .display-tc .form-inline .form-group {
  width: 100% !important;
  margin-bottom: 10px;
}

#performanz-header .display-tc .form-inline .form-group .form-control,
#performanz-counter .display-tc .form-inline .form-group .form-control,
.performanz-cover .display-tc .form-inline .form-group .form-control {
  width: 100%;
  background: #fff;
  border: none;
}

.performanz-bg {
  background-size: cover;
  background-position: center center;
  position: relative;
  width: 100%;
  float: left;
}

.performanz-bg .overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}

.performanz-cover {
  height: 800px;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  position: relative;
  float: left;
  width: 100%;
}

.performanz-cover .overlay {
  z-index: 0;
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.3);
}

.performanz-cover>.performanz-container {
  position: relative;
  z-index: 10;
}

@media screen and (max-width: 768px) {
  .performanz-cover {
    height: 600px;
  }
}

.performanz-cover .display-t,
.performanz-cover .display-tc {
  z-index: 9;
  height: 700px;
  display: table;
  width: 100%;
  margin-top: 100px;
}

@media screen and (max-width: 768px) {

  .performanz-cover .display-t,
  .performanz-cover .display-tc {
    height: 400px;
  }
}

.performanz-cover.performanz-cover-sm {
  height: 300px;
}

@media screen and (max-width: 768px) {
  .performanz-cover.performanz-cover-sm {
    height: 400px;
  }
}

.performanz-cover.performanz-cover-sm .display-t,
.performanz-cover.performanz-cover-sm .display-tc {
  height: 400px;
  display: table;
  width: 100%;
}

@media screen and (max-width: 768px) {

  .performanz-cover.performanz-cover-sm .display-t,
  .performanz-cover.performanz-cover-sm .display-tc {
    height: 400px;
  }
}

#performanz-services,
#performanz-started,
#performanz-testimonial,
#performanz-trainer,
#performanz-gallery,
#performanz-schedule,
#performanz-pricing,
#performanz-blog,
#performanz-contact,
#performanz-footer {
  padding: 7em 0;
  clear: both;
}

@media screen and (max-width: 768px) {

  #performanz-services,
  #performanz-started,
  #performanz-testimonial,
  #performanz-trainer,
  #performanz-gallery,
  #performanz-schedule,
  #performanz-pricing,
  #performanz-blog,
  #performanz-contact,
  #performanz-footer {
    padding: 3em 0;
  }
}

#performanz-footer {
  position: relative;
}

#performanz-footer .overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.9);
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}

#performanz-footer h3 {
  margin-bottom: 15px;
  font-weight: 700;
  font-size: 15px;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.8);
}

#performanz-footer .performanz-footer-links {
  padding: 0;
  margin: 0;
}

#performanz-footer .performanz-footer-links li {
  padding: 0;
  margin: 0;
  list-style: none;
}

#performanz-footer .performanz-footer-links li a {
  color: rgba(255, 255, 255, 0.5);
  text-decoration: none;
}

#performanz-footer .performanz-footer-links li a:hover {
  text-decoration: underline;
}

#performanz-footer .performanz-widget {
  margin-bottom: 30px;
}

@media screen and (max-width: 768px) {
  #performanz-footer .performanz-widget {
    text-align: left;
  }
}

#performanz-footer .performanz-widget h3 {
  margin-bottom: 15px;
  font-weight: 700;
  font-size: 15px;
  letter-spacing: 2px;
  text-transform: uppercase;
}

#performanz-footer .performanz-widget a {
  color: #f85a16 !important;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}

#performanz-footer .performanz-widget .btn {
  color: #fff !important;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}

#performanz-footer .performanz-widget a:hover,
a:active,
a:focus {
  color: #f85a16;
  outline: none;
  text-decoration: none;
}

#performanz-footer .performanz-widget .footer-link {
  margin-bottom: 15px;
  color: #f85a16 !important;
  padding: 0px !important;
}

#performanz-footer .copyright .performanz-social-icons .social-media-list {
  margin-bottom: 15px;
  color: #f85a16 !important;
}

#performanz-footer .copyright .block {
  display: block;
}

#performanz-footer .copyright .performanz-social-icons {
  margin: 0;
  padding: 0;
}

#performanz-footer .copyright .performanz-social-icons li {
  margin: 0;
  padding: 0;
  list-style: none;
  display: -moz-inline-stack;
  display: inline-block;
  zoom: 1;
  *display: inline;
}

#performanz-footer .copyright .performanz-social-icons a {
  display: -moz-inline-stack;
  display: inline-block;
  zoom: 1;
  *display: inline;
  color: #f85a16 !important;
  padding-left: 10px;
  padding-right: 10px;
}

#performanz-footer .copyright .performanz-social-icons a i {
  font-size: 36px !important;
}

p {
  margin-bottom: 20px;
}

h1,
h2,
h3,
h4,
h5,
h6,
figure {
  color: #000;
  font-family: work sans, Arial, sans-serif;
  font-weight: 400;
  margin: 0 0 20px;
}

::-webkit-selection {
  color: #fff;
  background: #f85a16;
}

::-moz-selection {
  color: #fff;
  background: #f85a16;
}

::selection {
  color: #fff;
  background: #f85a16;
}

.footer-linkxx {
  color: "#f85a16";
  transition: 0.5s;
}

.img-145 {
  max-width: 145px;
  max-height: 145px;
  width: auto\9;
}

.performanz-heading h2 {
  font-size: 30px;
  margin-bottom: 40px;
  line-height: 1.5;
  text-transform: uppercase;
  position: relative;
}

#performanz-schedule .performanz-heading h2 {
  color: #fff;
}

#performanz-schedule .performanz-heading p {
  color: rgba(255, 255, 255, 0.5);
}

#performanz-schedule .performanz-heading span {
  color: rgba(255, 255, 255, 0.5);
}

.performanz-heading {
  margin-bottom: 5em;
}

.performanz-heading h2 {
  font-size: 30px;
  margin-bottom: 40px;
  line-height: 1.5;
  color: #000;
  text-transform: uppercase;
  position: relative;
}

.performanz-heading h2:after {
  position: absolute;
  bottom: -10px;
  left: 0;
  right: 0;
  width: 60px;
  height: 2px;
  margin: 0 auto;
  background: #f85a16;
  content: "";
}

.performanz-heading p {
  font-size: 18px;
  line-height: 1.5;
  color: #828282;
}

.performanz-heading span {
  display: block;
  margin-bottom: 10px;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 2px;
}

.performanz-contact-info ul {
  padding: 0;
  margin: 0;
}

.performanz-contact-info ul li {
  padding: 0 0 0 40px;
  margin: 0 0 30px;
  list-style: none;
  position: relative;
}

.performanz-contact-info ul li:before {
  color: #f85a16;
  position: absolute;
  left: 0;
  top: 0.05em;
  font-family: icomoon;
  /* speak: none; */
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.trainer {
  position: relative;
  background: rgba(0, 0, 0, 0.05);
}

@media screen and (max-width: 768px) {
  .trainer {
    margin-bottom: 30px;
  }
}

.trainer img {
  width: 84%;
}

.trainer .title {
  width: 100%;
  position: absolute;
  bottom: 60%;
  left: 42%;
  -webkit-transform: rotate(270deg);
  -moz-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  -o-transform: rotate(270deg);
  transform: rotate(270deg);
}

.trainer .title h3 {
  font-size: 18px;
  text-transform: uppercase;
  letter-spacing: 3px;
  margin: 0;
  padding: 0;
}

.trainer .title h3 a {
  color: #000;
}

.trainer .title h3 a:hover,
.trainer .title h3 a:focus {
  color: #f85a16;
}

.trainer .title span {
  text-transform: uppercase;
  letter-spacing: 3px;
  font-size: 13px;
  color: rgba(0, 0, 0, 0.4);
}


@keyframes modal-video {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes modal-video-inner {
  from {
    transform: translate(0, 100px);
  }

  to {
    transform: translate(0, 0);
  }
}

.modal-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000000;
  cursor: pointer;
  opacity: 1;
  animation-timing-function: ease-out;
  animation-duration: 0.3s;
  animation-name: modal-video;
  -webkit-transition: opacity 0.3s ease-out;
  -moz-transition: opacity 0.3s ease-out;
  -ms-transition: opacity 0.3s ease-out;
  -o-transition: opacity 0.3s ease-out;
  transition: opacity 0.3s ease-out;
}

.modal-video-effect-exit {
  opacity: 0;
}

.modal-video-effect-exit .modal-video-movie-wrap {
  -webkit-transform: translate(0, 100px);
  -moz-transform: translate(0, 100px);
  -ms-transform: translate(0, 100px);
  -o-transform: translate(0, 100px);
  transform: translate(0, 100px);
}

.modal-video-body {
  max-width: 960px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
}

.modal-video-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

@media (orientation: landscape) {
  .modal-video-inner {
    padding: 10px 60px;
    box-sizing: border-box;
  }
}

.modal-video-movie-wrap {
  width: 100%;
  height: 0;
  position: relative;
  padding-bottom: 56.25%;
  background-color: #333;
  animation-timing-function: ease-out;
  animation-duration: 0.3s;
  animation-name: modal-video-inner;
  -webkit-transform: translate(0, 0);
  -moz-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0);
  -webkit-transition: -webkit-transform 0.3s ease-out;
  -moz-transition: -moz-transform 0.3s ease-out;
  -ms-transition: -ms-transform 0.3s ease-out;
  -o-transition: -o-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
}

.modal-video-movie-wrap iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.modal-video-close-btn {
  position: absolute;
  z-index: 2;
  top: -45px;
  right: 0px;
  display: inline-block;
  width: 35px;
  height: 35px;
  overflow: hidden;
  border: none;
  background: transparent;
}

@media (orientation: landscape) {
  .modal-video-close-btn {
    top: 0;
    right: -45px;
  }
}

.modal-video-close-btn:before {
  transform: rotate(45deg);
}

.modal-video-close-btn:after {
  transform: rotate(-45deg);
}

.modal-video-close-btn:before,
.modal-video-close-btn:after {
  content: '';
  position: absolute;
  height: 2px;
  width: 100%;
  top: 50%;
  left: 0;
  margin-top: -1px;
  background: #fff;
  border-radius: 5px;
  margin-top: -6px;
}

.img-responsive {
  width: 500px;
}

ul {
  list-style-type: disc;
  list-style-position: inside;
}

ol {
  list-style-type: decimal;
  list-style-position: inside;
}

ul ul,
ol ul {
  list-style-type: circle;
  list-style-position: inside;
  margin-left: 15px;
}

ol ol,
ul ol {
  list-style-type: lower-latin;
  list-style-position: inside;
  margin-left: 15px;
}

.my_container ul {
  list-style: initial !important;
  margin: initial !important;
  padding: 0 0 0 40px;
}

.my_container li {
  list-style-type: circle !important;
  display: list-item !important;
}

.btn-primary {
  background: #F85A16;
  color: #fff !important;
  border: 2px solid #F85A16;
  border-radius: 30px !important;
}

a {
  color: #f85a16 !important;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}

a:hover,
a:active,
a:focus {
  color: #f85a16;
  outline: none;
  text-decoration: none;
}

#performanz-portfolio-list {
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  position: relative;
  float: left;
  padding: 0;
  margin: 0;
  width: 100%;
}

@media screen and (max-width: 480px) {
  #performanz-portfolio-list li.one-third {
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  #performanz-portfolio-list li.one-third {
    width: 49%;
  }
}

#performanz-portfolio-list li.one-third {
  width: 23.8%;
}

@media screen and (max-width: 480px) {
  #performanz-portfolio-list li.two-third {
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  #performanz-portfolio-list li.two-third {
    width: 49%;
  }
}

#performanz-portfolio-list li.two-third {
  width: 48.6%;
}

@media screen and (max-width: 480px) {
  #performanz-portfolio-list li.one-third {
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  #performanz-portfolio-list li.one-third {
    width: 49%;
  }
}



@media screen and (max-width: 480px) {
  #performanz-portfolio-list li {
    margin-left: 0;
  }
}

#performanz-portfolio-list li {
  display: block;
  padding: 0;
  margin: 0 0 15px 1%;
  list-style: none;
  min-height: 400px;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  float: left;
  clear: left;
  position: relative;
}

#performanz-portfolio-list li a:before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: "";
  z-index: 80;
  -webkit-transition: .5s;
  -o-transition: .5s;
  transition: .5s;
  background: transparent;
}

@media screen and (max-width: 768px) {
  #performanz-portfolio-list li .case-studies-summary {
    bottom: 1em;
    left: 1em;
    right: 1em;
  }
}

#performanz-portfolio-list li .case-studies-summary {
  width: auto;
  bottom: 2em;
  left: 2em;
  right: 2em;
  position: absolute;
  z-index: 100;
}

@media screen and (max-width: 768px) {
  #performanz-portfolio-list li a .case-studies-summary h2 {
    font-size: 20px;
  }
}

#performanz-portfolio-list li .case-studies-summary h2 {
  color: #fff !important;
  margin-bottom: 0;
}

#performanz-portfolio-list li .case-studies-summary span {
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 13px;
  color: rgba(255, 255, 255, 0.7) !important;
}

.scale-card:hover {
  transform: scale(1.1);
}

.scale-card-img,
.scale-card-text {
  transition: none !important;
}

.scale-card {
  transition: transform 0.2s ease-in-out;
  border-radius: 6px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.scale-card-img {
  width: 80px;
}

.scale-card-text {
  margin: 0;
  font-size: 16px;
  letter-spacing: 0.4px;
  text-transform: uppercase;
  font-weight: 500;
  text-align: center;
}

/* Media Queries */
@media (max-width: 1200px) {
  .scale-card {
      width: 20% !important; /* 1200px ve üzeri için 5 kart */
  }
}

@media (max-width: 992px) {
  .scale-card {
      width: 30% !important; /* 992px ile 1200px arasında 4 kart */
  }
}

@media (max-width: 768px) {
  .scale-card {
      width: 40% !important; /* 768px ile 992px arasında 3 kart */
  }
}

@media (max-width: 480px) {
  .scale-card {
      width: 80% !important; /* 480px ile 768px arasında 2 kart */
  }
}

@media (max-width: 320px) {
  .scale-card {
      width: 90% !important; /* 320px ve altı için tek kart */
  }
}
